import { render, staticRenderFns } from "./DocumentsComponent.vue?vue&type=template&id=ef75057e&scoped=true"
import script from "./DocumentsComponent.vue?vue&type=script&lang=js"
export * from "./DocumentsComponent.vue?vue&type=script&lang=js"
import style0 from "./DocumentsComponent.vue?vue&type=style&index=0&id=ef75057e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef75057e",
  null
  
)

export default component.exports