<template>
  <v-container class="mx-0" fluid>
    <template v-if="loading.documents.state">
      <v-skeleton-loader
        style="height: 12vh;"
        type="image"
        class="mb-2"
      ></v-skeleton-loader>
      <v-skeleton-loader
        style="height: 12vh;"
        type="image"
        class="mb-2"
      ></v-skeleton-loader>
      <v-skeleton-loader style="height: 12vh;" type="image"></v-skeleton-loader>
    </template>
    <template v-else>
      <v-card
        v-for="(doc, idx) in documents"
        :key="`card-${idx}`"
        elevation="1"
        class="pa-3 mb-10 text-start"
      >
        <h4 class="grey--text text--lighten-1 mb-2 text-uppercase">
          {{ doc.title }}
        </h4>
        <v-alert
          v-if="!doc.documents.length"
          :value="true"
          type="info"
          class="white--text mx-3 text-center"
        >
          Maaf data dokumen pelayanan pasien tidak ditemukan
        </v-alert>
        <v-card
          v-for="(item, index) in doc.documents"
          :key="`document-${item.title}-${index}`"
          class="document-card"
          elevation="0"
        >
          <v-row class="item-container">
            <v-col class="d-flex justify-start align-center text-start">
              <h3>
                {{ item.title }}
              </h3>
            </v-col>
            <v-col class="d-flex flex-column">
              <label class="grey--text body-2 text-start mb-2"
                >Tanggal Pembuatan Dokumen
              </label>
              <label class="font-weight-medium body-2 text-start">{{
                item.dateTime || '-'
              }}</label>
            </v-col>
            <v-col class="pa-0 text-start pt-2">
              <label class="grey--text body-2 text-start"
                >Yang Bertandatangan
              </label>
              <v-row class="pa-4 pl-3">
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{ item.patient || '-' }}</span>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <label
                    v-if="item.patient"
                    class="subtitle-2 font-weight-light text-capitalize"
                    >{{ item.signer }}
                  </label>
                </v-col>
                <v-col cols="6" class="pa-0"
                  ><span class="subtitle-2">{{ item.staff || '' }}</span>
                </v-col>
                <v-col cols="6" class="pa-0">
                  <label v-if="item.staff" class="subtitle-2 font-weight-light"
                    >Petugas</label
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-flex align-end justify-end">
              <v-progress-linear
                v-model="item.loading.value"
                v-if="item.loading.state"
                class="mr-3"
                color="primary"
                height="15"
                light
                dark
                rounded
              >
                <template v-slot:default="{ value }">
                  <h5>{{ Math.ceil(value) }}%</h5>
                </template>
              </v-progress-linear>
              <div class="file-container" v-if="item.document.length > 0">
                <div
                  style="width: 100%"
                  @click="previewFile(item.document[0].path)"
                >
                  {{ item.document[0].name }}
                </div>
                <v-icon
                  style="z-index: 2"
                  @click="deleteFile(item, idx, index)"
                  color="red"
                  >mdi-close</v-icon
                >
              </div>
              <div class="file-container error" v-if="item.documentError.file">
                <span class="red--text">{{
                  item.documentError.message + '.'
                }}</span>
                <span
                  style="cursor: pointer;"
                  class="text-decoration-underline red--text"
                  @click="addFile(idx, item.index)"
                  >Coba lagi</span
                >
                <v-icon @click="removeWarning(item.index)" color="red"
                  >mdi-close
                </v-icon>
              </div>
              <input
                type="file"
                id="files"
                :ref="`files-${idx}-${item.index}`"
                accept="image/png, image/jpeg, application/pdf"
                multiple
                v-on:change="handleFilesUpload(item, idx, index)"
                v-show="false"
              />
              <v-tooltip
                v-if="
                  !item.document.length &&
                    !item.documentError.file &&
                    item.dateTime &&
                    !item.loading.state
                "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="addFile(idx, item.index)"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    class="btn-icon mx-2"
                    slot="activator"
                  >
                    <v-icon>mdi-upload</v-icon>
                  </v-btn>
                </template>
                <span>Upload Dokumen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="btn-icon"
                    @click="openForm(item)"
                    :class="{
                      'ml-3':
                        item.document.length > 0 || item.documentError.file,
                    }"
                    slot="activator"
                  >
                    <v-icon>mdi-file-document-edit</v-icon>
                  </v-btn></template
                >
                <span>Form {{ item.title }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </template>
    <v-dialog v-model="dialog" :max-width="selectedForm === 3 ? 1120 : null">
      <general-consent-form
        v-if="dialog && id_emr && selectedForm === 0"
        :rm-number="patientData.medical_record_number"
        :id-inpatient="selectedDocument.id_inpatient"
        :service-type="
          selectedDocument.id_inpatient ? 'Rawat Inap' : 'Rawat Jalan'
        "
        @close-form="dialog = false"
        is-emr
      />
      <form-informed-consent
        v-if="dialog && selectedForm === 1"
        state="detail"
        @close-form="dialog = false"
        persistent
      />
      <surgical-safety-checklist
        :closeForm="() => (dialog = false)"
        v-if="dialog && selectedForm === 2"
      />
      <form-operation-marking
        ref="formOperationMarking"
        v-if="dialog && selectedForm === 3"
        state="emr"
        @close-form="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
// utils
import Swal from 'sweetalert2';
import Constant from '@/const';
import moment from 'moment-timezone';
const axios = require('axios');

// mixins
import AlertMixin from '@/mixin/alertMixin';
import formatMixin from '@/mixin/formatMixin';

// components
import FormInformedConsent from '../../Registration/components/InformedConsent/FormInformedConsent';
import GeneralConsentForm from '../../Registration/components/GeneralConsentForm';
import SurgicalSafetyChecklist from '../../Service/components/ConsentDocuments/SurgicalSafetyChecklist';
import FormOperationMarking from '../../Registration/components/OperationMarking/FormOperationMarking';

// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapMutations } = createNamespacedHelpers('InformedConsent');
const {
  mapActions: mapSscActions,
  mapMutations: mapSscMutations,
} = createNamespacedHelpers('SurgicalSafetyChecklist');
const { mapActions: opActions } = createNamespacedHelpers('OperationMarking');

// api
import { getAllDocumentsByIdEmr } from '@/fetchApi/Documents';

export default {
  name: 'GeneralConsent',
  mixins: [AlertMixin, formatMixin],
  components: {
    GeneralConsentForm,
    FormInformedConsent,
    FormOperationMarking,
    SurgicalSafetyChecklist,
  },
  props: {
    id_emr: {
      type: String,
    },
    patientData: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      selectedForm: null,
      selectedDocument: null,
      documents: [],
      loading: {
        documents: {
          state: false,
          value: 0,
        },
      },
    };
  },
  watch: {
    dialog() {
      this.getAllDocuments();
    },
  },
  mounted() {
    this.getAllDocuments();
  },
  computed: {
    isFinanceManager() {
      return this.$store.getters.userLoggedIn.role;
    },
  },
  methods: {
    ...mapMutations([
      'setIdIC',
      'setStateForm',
      'setIsChanged',
      'setShowDialog',
      'setDataPatient',
      'clearFormInformedConsent',
      'clearItemsInformedConsent',
    ]),
    ...mapSscMutations(['setIsEmr']),
    ...mapActions([
      'resolveGetInformConsentById',
      'resolveGetStaff',
      'resolveGetAllService',
    ]),
    ...mapSscActions(['resolveGetSurgicalSafetyChecklistById']),
    ...opActions(['resolveGetOperationMarkingById']),
    openForm(data) {
      this.dialog = true;
      if (data.tag === 'generalconsent') {
        this.selectedDocument = {
          id_inpatient: data.id_inpatient,
        };
        this.selectedForm = 0;
      }
      if (data.tag === 'surgicalsafetychecklist') {
        const data_patient = {
          rmNumber: this.patientData.medical_record_number,
          meta: {
            name: this.patientData.social.name,
            birthDate: moment(
              this.patientData.social.birth.date,
              'YYYY-MM-DD',
            ).format('DD MMMM YYYY'),
            gender: this.patientData.social.gender ? 'Laki-laki' : 'Perempuan',
            age: this.patientData.social.age,
          },
          id_emr: this.id_emr,
        };
        this.$store.commit('changeSelectedPatient', data_patient);
        this.setIsEmr(true);
        this.resolveGetSurgicalSafetyChecklistById(data.id);
        this.selectedForm = 2;
      }
      if (data.tag === 'informconsent') {
        this.setDataPatient({
          noRM: this.patientData.medical_record_number,
          name: this.patientData.social.name,
          birthDate: moment(this.patientData.social.birth.date)
            .locale('id')
            .format('LL'),
          birthPlace: this.patientData.social.birth.place,
          address: this.patientData.social.address.text,
          age: this.patientData.social.age,
        });
        this.selectedForm = 1;
        this.setStateForm('detail');
        this.setIdIC(data.id);
        this.resolveGetStaff();
        this.resolveGetInformConsentById(data.id).then(response => {
          this.resolveGetAllService({
            keyword:
              response.detail?.information_checklist?.action?.value?.name,
            guarantorType: this.patientData.social.assurance.type.toLowerCase(),
          });
        });
      }
      if (data.tag === 'medicaltreatment') {
        this.setDataPatient({
          noRM: this.patientData.medical_record_number,
          name: this.patientData.social.name,
          birthDate: moment(this.patientData.social.birth.date)
            .locale('id')
            .format('LL'),
          birthPlace: this.patientData.social.birth.place,
          address: this.patientData.social.address.text,
          age: this.patientData.social.age,
          gender: this.patientData.social.gender ? 'Laki-laki' : 'Perempuan',
        });
        this.selectedForm = 3;
        this.resolveGetOperationMarkingById(data.id);
      }
    },
    async getAllDocuments() {
      this.loading.documents.state = true;
      try {
        const response = await getAllDocumentsByIdEmr(this.id_emr);
        this.documents = response.data.data.map(item => {
          return {
            title: item.title,
            documents: item.documents.map((doc, index) => {
              return {
                index,
                id: doc._id,
                id_inpatient: doc.id_inpatient,
                action: doc.action,
                dateAction: doc.date,
                title: doc.title,
                tag: this.generateTag(doc.title),
                dateTime: doc.date || doc.document_created,
                signer: doc.signer,
                patient: doc.signer_name,
                staff: doc.signer_doctor || '-',
                companion: doc.signer_staff || '-',
                document: doc.files
                  ? [
                      {
                        name: doc.files.files.original_file_name,
                        id: doc.files.files._id,
                        path: doc.files.files.path,
                      },
                    ]
                  : [],
                documentError: {
                  file: '',
                  message: '',
                },
                loading: {
                  state: false,
                  value: 0,
                },
              };
            }),
          };
        });
      } catch {
        this.documents = [];
      } finally {
        this.loading.documents.state = false;
      }
    },
    addFile(indexSection, indexDocument) {
      this.$refs[`files-${indexSection}-${indexDocument}`][0].click();
    },
    async previewFile(path) {
      window.open(Constant.apiUrl.concat(`/${path}`));
    },
    handleFilesUpload(data, indexSection, indexDocument) {
      const filesUploaded = this.$refs[
        `files-${indexSection}-${indexDocument}`
      ][0].files;
      if (filesUploaded.length > 1) {
        this.documents[indexSection].documents[indexDocument].documentError = {
          file: filesUploaded,
          message: 'Max hanya 1 file',
        };
        return;
      }
      if (this.verify(filesUploaded[0]) === '') {
        this.documents[indexSection].documents[indexDocument].documentError = {
          file: '',
          message: '',
        };
        this.uploadFile(filesUploaded[0], data, indexSection, indexDocument);
      } else {
        this.documents[indexSection].documents[indexDocument].documentError = {
          file: filesUploaded[0],
          message: this.verify(filesUploaded[0]),
        };
      }
      this.$refs[`files${data.index}`][0].files.value = '';
    },
    verify(file) {
      const maxSize = 2000000;
      const allowedTypes = ['application/pdf'];
      const ext = file.name.split('.');
      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`;
      }
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 2MB';
      }

      return '';
    },
    postFile(isUpload) {
      axios
        .post(
          Constant.apiUrl.concat('/document/general-consent/upload'),
          {
            isUploaded: isUpload,
            id_emr: this.id_emr,
          },
          {
            onUploadProgress: progressEvent => {
              const progress =
                Math.floor(progressEvent.loaded / progressEvent.total) * 50;
              this.documents[0].documents[0].loading.value =
                this.documents[0].documents[0].loading.value + progress;
              if (this.documents[0].documents[0].loading.value == 100) {
                this.documents[0].documents[0].loading.state = false;
              }
            },
          },
        )
        .then(response => {
          if (response)
            this.showFillSuccess(
              `Berhasil ${isUpload ? 'Mengunggah' : 'Menghapus'} File`,
            );
        });
    },
    async uploadFile(file, data, indexSection, indexDocument) {
      let identifier;
      if (!indexSection && !indexDocument) {
        identifier = this.id_emr;
      } else {
        identifier = data.id_inpatient || data.id;
      }

      if (data.tag === 'informconsent') {
        identifier = data.id;
      }

      this.documents[indexSection].documents[
        indexDocument
      ].loading.state = true;
      const formData = new FormData();
      formData.append(`files`, file);
      formData.append('identifier', identifier);
      formData.append('tag', data.tag);
      formData.append('created_by', this.$store.getters.userLoggedIn.id);

      await axios
        .post(
          Constant.apiUrl.concat('/upload'),
          formData,
          {
            onUploadProgress: progressEvent => {
              // calculate for loading progress
              const progress =
                Math.floor(progressEvent.loaded / progressEvent.total) *
                  data.tag ===
                'informconsent'
                  ? 50
                  : 100;
              this.documents[indexSection].documents[
                indexDocument
              ].loading.value =
                this.documents[indexSection].documents[indexDocument].loading
                  .value + progress;
              if (
                this.documents[indexSection].documents[indexDocument].loading
                  .value == 100
              ) {
                this.documents[indexSection].documents[
                  indexDocument
                ].loading.state = false;
              }
            },
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(async () => {
          if (!indexSection && !indexDocument) {
            await this.postFile(true, data.index);
          }
          this.showFillSuccess('Berhasil Mengunggah File');
          this.getAllDocuments();
        })
        .catch(error => {
          this.showErrorAxios(error);
        });
    },
    async removeFile(file, data, indexSection, indexDocument) {
      let identifier;
      if (!indexSection && !indexDocument) {
        identifier = this.id_emr;
      } else {
        identifier = data.id_inpatient || data.id;
      }
      const payload = {
        tag: data.tag,
        identifier,
        file_id: [file.id],
      };
      await axios.delete(
        Constant.apiUrl.concat('/upload'),
        { data: payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (data.index) {
        this.showFillSuccess('Berhasil Menghapus File');
      }
    },
    deleteFile(data, indexSection, indexDocument) {
      if (
        this.documents[indexSection].documents[indexDocument].document.length >
        0
      ) {
        Swal.fire({
          title: `Apakah Anda yakin ingin Menghapus Dokumen ${data.title} untuk Pasien ${this.documents[indexSection].documents[indexDocument].patient}?`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#2D9CDB',
          cancelButtonColor: '#EB5757',
          confirmButtonText: 'Hapus',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        })
          .then(async result => {
            if (result.isConfirmed) {
              await this.removeFile(
                this.documents[indexSection].documents[indexDocument]
                  .document[0],
                data,
                indexSection,
                indexDocument,
              );
              await this.postFile(false, data.index);
            }
          })
          .catch(error => {
            this.showErrorAxios(error);
          })
          .finally(() => {
            this.getAllDocuments();
          });
      } else {
        this.documents[indexSection].documents[indexDocument].documentError = {
          file: '',
          message: '',
        };
      }
    },
    removeWarning(index) {
      this.documents[index].documentError = {
        file: '',
        message: '',
      };
    },
    generateTag(data) {
      let result;
      switch (data.toLowerCase()) {
        case 'general consent rawat jalan':
        case 'general consent rawat inap':
          result = 'generalconsent';
          break;
        case 'informed consent rawat jalan':
        case 'informed consent rawat inap':
          result = 'informconsent';
          break;
        case 'surgical safety checklist':
          result = 'surgicalsafetychecklist';
          break;
        case 'penandaan operasi atau tindakan medis':
          result = 'medicaltreatment';
          break;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.document-card {
  border: 1px solid #137bc0;
  width: 100%;
  border-radius: 8px;
  padding: 1rem;
  min-height: 6rem;
  margin-bottom: 1rem;
  .item-container {
    min-height: 6rem;
  }
}
.search {
  border: 1px solid #9ca2a5;
  border-radius: 3px;
  width: 150px;
}

.btn-icon {
  border-color: #cdd2d7;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
}

.file-container {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  text-align: start;
  font-weight: 500;
  padding: 0.5rem;
  font-size: 0.9rem;
  background-color: #f5f5f5;
  min-width: 15rem;

  &.error {
    min-width: 20rem;
    background-color: #f5f5f5 !important;
  }
}
</style>
